<template>
  <BudgetsForm
    :budget="budget ? budget : getCurrentBudget()"
    :type="'edit'"
    @save="updateBudget"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    BudgetsForm: () => import('./BudgetsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    budget: { seller_exist: false },
    askToLeave: true,

  }),
  created() {
    this.$store.commit('resetProducts')
    this.getCurrentBudget()
  },
  methods: {
    getCurrentBudget() {
      this.$api
        .post(
          'budget/show',
          { id: this.$route.params.id },
          {
            headers: {
              Authorization: `Beader ${this.$cookies.get('token')}`,
            },
          }
        )
        .then((res) => {
          this.budget = res.data.data

          if (this.budget.seller_id != null) {
            this.budget.seller_exist = true
          } else {
            this.budget.seller_exist = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateBudget() {
      this.askToLeave = false
      
      let total =
        this.$store.state.budgetTotal.length > 0
          ? this.$store.state.budgetTotal.reduce((acc, curr) => acc + curr)
          : 0
      let orderTotal =
        this.$store.state.orderTotal.length > 0
          ? this.$store.state.orderTotal.reduce((acc, curr) => acc + curr)
          : 0
      let canApprove = this.$store.state.budgetApproval.reduce(
        (acc, curr) => acc + curr
      )
      canApprove = canApprove > 0 ? false : true

      const productsToRemove = JSON.parse(JSON.stringify(this.$store.state.productsToRemove))
      const budgetProducts = JSON.parse(JSON.stringify(this.$store.state.budgetProducts))
      const toSendProducts = budgetProducts.filter(product => {
        const toRemove = productsToRemove.find(productToRemove => {
          return product.product_id == productToRemove.product_id && product.color_id == productToRemove.color_id
        })
        return !toRemove
      })


      this.$api
        .post(`budget/update`, {
          ...this.budget,
          products: toSendProducts,
          total_cost: total,
          can_approve: canApprove,
          subtotal: parseFloat(orderTotal.toFixed(2)),
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Orçamento atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'budgets' })
            },
          })
          this.$store.commit('resetTotal')
          this.$store.commit('resetApproval')
        })
        .catch((err) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este Orçamento',
            caption: err.response.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .finally(() => {
          this.$store.commit('resetProducts')
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
